import React, { FunctionComponent, useState, useContext } from 'react';
import './App.css';
import LoginPage from "./users"
import AppType from "./types/App"
import Snackbar from '@material-ui/core/Snackbar';
import { Session } from "@okidou/spare-model"
import { PlayerList as EditPlayersList } from "./playerLists"
import { EditSpare } from "./spares"
import StateProvider from "./state/StateProvider"
import {AppContext} from "./state/StateProvider"

interface Props {
  app: AppType
}

const App: FunctionComponent<Props> = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [session, setSession] = useState({})

  const error = (message:string) => {
    setMessage(message)
    setOpen(true)
  }

  const storeSession = (session: Session) => {
    setSession(session)
  }

  const {state} = useContext(AppContext)
  console.log(state)
  return (
    <StateProvider>
      <div className="App">
        {
        ('id' in session ) ?
          (
            state && ('playersList' in state)?
              <EditSpare />
            :
              <EditPlayersList app={props.app} error={error} />
          )
        :
          <LoginPage app={props.app} error={error} storeSession={storeSession}/>
        }
        <Snackbar
            message={message}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
            open={open}
          />
      </div>
    </StateProvider>

  );
}

export default App;
