import Action from "../types/Action"
const reducer = (state: object, action: Action) => {
    console.log("Reducerr")
    switch (action.type) {
        case 'PLAYERS_LIST_FETCHED':
            console.log(state)

            return {...state, playersList: action.payload}
    }
    return state
}

export default reducer