import React, { FunctionComponent, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from "./okidou-logo.svg"
import AppType from '../types/App'
import { Session } from "@okidou/spare-model"

interface Props {
  app: AppType,
  error: (message: string) => void,
  storeSession: (session: Session) => void
}

const LoginPage: FunctionComponent<Props> = (props) => {
  const [credentials, setCredentials] = useState(
    {
      email: '',
      password: ''
    }
  )

  const login = (event: any) => {
      props.app.client.login(credentials.email, credentials.password).then(
        (session) => {
          props.storeSession(session)
        }
      )
      .catch(
        (error) => props.error('Erreur de connexion')
      )

  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <img src={logo} alt="Logo"/>
        </Grid>
        <Grid item xs={12} >
          <TextField
            label="Courriel"
            onChange={(event)=> setCredentials({...credentials, email: event?.target.value})}
            value={credentials.email}
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            label="Mot de passe"
            onChange={(event)=> setCredentials({...credentials, password: event?.target.value})}
            value={credentials.password}
            type="password"
          />
        </Grid>
        <Grid item xs={12} >
          <Button onClick={login} >Connexion</Button>
        </Grid>
      </Grid>
    </Container>
    );
  }

  export default LoginPage;
