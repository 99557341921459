import React, { FunctionComponent } from 'react';

interface Props {

}

const EditSpare: FunctionComponent<Props> = (props: Props) => {
    return (
    <div>
            Test
        </div>
    )
}

export default EditSpare