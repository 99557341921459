import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { genders, hockeyTypes } from "@okidou/spare-model";
import React, { FunctionComponent, useState, useContext } from 'react';
import AppType from '../types/App'
import { PlayersList } from '@okidou/spare-model';
import {AppContext} from "../state/StateProvider"

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));
interface Props {
  app: AppType,
  error: (message: string) => void,
}

const PlayersListPage: FunctionComponent<Props> = (props) => {
  const {dispatch} = useContext(AppContext)
  const [playersList, setPlayersList] = useState(
    {
      sport: Object.keys(hockeyTypes)[0],
      gender: Object.keys(genders)[0]
    }
  )
  
  const selectSport = (value: string) => setPlayersList(
    {
      ...playersList,
      sport: value
    }
  )
  const selectGender = (value: string) => setPlayersList(
    {
      ...playersList,
      gender: value
    }
  )

  const savePlayersList = ()  => {
    props.app.client.savePlayersList(playersList).then(
      (response: PlayersList) => {
        dispatch({
          type: "PLAYERS_LIST_FETCHED",
          payload: response
        })
      }
    )
  }

  const classes = useStyles();
  
  return (
    <form className={classes.root} noValidate autoComplete="off">
    <Grid container spacing={3}>
    <Grid item xs={12} >
    <h1>Créez votre liste</h1>
    </Grid>
    
    <Grid item xs={12} >
    <FormControl>
    <InputLabel>Sport</InputLabel>
    <Select
    required={true}
    value={playersList.sport}
    onChange={(event) => selectSport(event.target.value as string)}
    >
    {
      Object.entries(hockeyTypes).map(
        ([key, value]) =>
        <MenuItem
        key={key}
        value={key}
        >
        {value}
        </MenuItem>
        )
      }
      </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12} >
      <FormControl>
      <InputLabel>Genre</InputLabel>
      <Select
      required={true}
      value={playersList.gender}
      onChange={(event) => selectGender(event.target.value as string)}
      >
      {
        Object.entries(genders).map(
          ([key, value]) =>
          <MenuItem
          key={key}
          value={key}
          >
          {value}
          </MenuItem>
          )
        }
        </Select>
        </FormControl>
        
        </Grid>
        <Grid item xs={12} >
        <Button onClick={() => savePlayersList()}>Enregistrer</Button>
        </Grid>
        </Grid>
        </form>
        );
      }
      
      export default PlayersListPage;
      