import React, { createContext, useReducer } from 'react';
import reducer from "./reducer"

export const AppContext = createContext<any>({});

const StateProvider = ({children}: {children: any}) => {
    const [state, dispatch] = useReducer(reducer, {})

    return (
      <AppContext.Provider value={{state, dispatch}}>
        {children}
      </AppContext.Provider>

    )
}

export default StateProvider 
