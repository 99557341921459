import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppType from './types/App';

import * as serviceWorker from './serviceWorker';

import createClient from "@okidou/spare-client"

const client = createClient(
  {
    baseUrl: (process.env.NODE_ENV === 'production')?'https://spare-api.dev.yr.lc':'http://localhost:3001',
  }
)

const app: AppType = {
  client
}

ReactDOM.render(
  <React.StrictMode>
    <App app={app} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
